import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, pageContext }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const pageTitle =
    (pageContext.title ? pageContext.title : `All posts`) +
    (pageContext.pageNumber && pageContext.pageNumber > 0
      ? `Page ${pageContext.humanPageNumber} of ${pageContext.numberOfPages}`
      : ``)
  const articles = data.directus.articles ? data.directus.articles : []
  let nextPage = pageContext.nextPagePath ? pageContext.nextPagePath : "/"
  let nextPageDisable = !!pageContext.nextPagePath
  let prevPage =
    pageContext.previousPagePath && pageContext.previousPagePath !== "/page"
      ? pageContext.previousPagePath
      : "/"
  let prevPageDisable =
    pageContext.previousPagePath && pageContext.previousPagePath !== "/page"

  return (
    <Layout title={siteTitle}>
      <Seo title={pageTitle} />
      <div className="container md:mx-auto mt-6">
        <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-2 place-items-stretch">
          {articles.map(article => {
            return (
              <article
                itemScope
                itemType="http://schema.org/Article"
                key={article.slug}
              >
                <div className="max-w-3xl mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800 h-full">
                  <Link itemProp="url" to={`/${article.slug}/`}>
                    <img
                      className="object-cover w-full h-64"
                      src={
                        "https://static.snorkout.com/" +
                        article.image.id +
                        "?key=crop-homepage"
                      }
                      alt={article.title}
                    />
                  </Link>
                  <div className="p-3 pt-1 md:p-4">
                    <div className="mt-4">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <span className="text-xs font-medium text-blue-600 uppercase dark:text-blue-400">
                            {article.category.title}
                          </span>
                        </div>
                        <span className="mx-1 text-xs text-gray-600 dark:text-gray-300">
                          {article.published_date_func.month}/
                          {article.published_date_func.day}/
                          {article.published_date_func.year}
                        </span>
                      </div>
                    </div>
                    <Link
                      itemProp="url"
                      to={`/${article.slug}/`}
                      className="block mt-2 text-2xl font-semibold text-gray-800 transition-colors duration-200 transform dark:text-white hover:text-gray-600 hover:underline"
                    >
                      {article.title}
                    </Link>
                  </div>
                </div>
              </article>
            )
          })}
        </div>

        <div className="flex justify-center my-5">
          <nav aria-label="Page navigation example">
            <ul className="flex list-style-none">
              {(prevPageDisable || pageContext.humanPageNumber === 2) && (
                <li className="page-item">
                  <Link
                    className="page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
                    to={prevPage}
                    aria-disabled={prevPageDisable}
                  >
                    Previous
                  </Link>
                </li>
              )}
              {nextPageDisable && (
                <li className="page-item">
                  <Link
                    className="page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
                    to={nextPage}
                    aria-disabled={nextPageDisable}
                  >
                    Next
                  </Link>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const query = graphql`
  query ($skip: Int!, $limit: Int!, $filter: DirectusData_articles_filter!) {
    site {
      siteMetadata {
        title
      }
    }
    directus {
      articles(
        filter: $filter
        offset: $skip
        limit: $limit
        sort: "-published_date"
      ) {
        title
        slug
        published_date_func {
          day
          month
          year
        }
        image {
          id
        }
        category {
          title
          slug
        }
        author {
          name
          slug
        }
      }
    }
  }
`
